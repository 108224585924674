<template>
  <div class="grid-container">
    <div class="header">
      <v-card class="header-card">
      <v-row no-gutters class="mb-2">
        <v-col cols="12" sm="7" class="d-flex flex-wrap">
        <div class="search-date">
          <date-picker
            :outlined="true"
            :filled="true"
            :value="date"
            label="Datum"
            :dateFormatted="dateFormatted"
            @changeDate="changeDate"
          ></date-picker>
       </div>
           <div class="search-client">
            <search-client
                :searchString="searchString"
                @setSearchString="setSearchString"
                @get-results="getClient"
                @clear-input="clearClient"
              ></search-client>
           </div>
        </v-col>
        <v-col cols="12" sm="5">
           <div class="outstanding-totals d-flex justify-space-between">
<fieldset class="fieldset">
              <legend class="legend">Te factureren</legend>
              {{ toCurrency(orderDebit) }}
            </fieldset>
            <fieldset class="fieldset">
              <legend class="legend">Openstaand</legend>
              {{ toCurrency(invoiceDebit) }}
            </fieldset>
           </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="7">
          <v-btn-toggle style="background:none" class="mb-2 white--text d-flex flex-wrap ">
            <v-btn color="secondary" @click="selectCategory('Recent')">
              <h3>Recent</h3>
            </v-btn>
            <v-btn
              color="secondary"
              @click="selectCategory(category)"
              v-for="category in this.$store.state.settings.categories"
              :key="category"
            >
              <h3>{{ category }}</h3>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" sm="5">
          <v-btn-toggle class="mb-2 white--text d-flex flex-wrap">
            <v-btn color="secondary" @click="openPrintQuoteModal"
              >Offerte</v-btn
            >
            <v-btn color="secondary" @click="openPrintOrderModal">Pakbon</v-btn>
            <v-btn color="secondary" @click="openPrintInvoiceModal"
              >Factuur</v-btn
            >
            <v-btn
              color="secondary"
              :disabled="order._id ? true : false"
              @keydown.enter="insertOrder"
              @click="insertOrder"
              >Opslaan</v-btn
            >
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="7">
          <v-text-field
            class="small-text-field"
            outlined            
            label="Artikel zoeken"
            v-model="searchItems"
            @click="searchItems = ''"
            hide-details
          ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" >
          <v-text-field
            outlined            
            v-model="order.notes"
            label="Opmerking"
            hide-details
          ></v-text-field>
          </v-col>
      </v-row>
    </v-card>
    </div>
    <v-card tile class="sidebar">
      <div class="d-flex flex-column">
        <v-card flat class="v-card-color">
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-col>
                  <select v-model="order.route" class="select">
                    <option value="" disabled selected>Kies een route</option>
                        <option
                          v-for="(Route, index) of allRoutes"
                          :key="index"
                          :value="Route.route"
                          >{{ Route.route }} [{{ Route.length }}]</option
                        >
                      </select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-select
                    dense
                    :items="employees"
                    item-text="name"
                    item-value="_id"
                    v-model="order.assigned"
                    label="Medewerker"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-data-table
            dense
            :headers="orderItemHeaders"
            :items="orderItems"
            item-key="itemNumber"
            disable-pagination
            disable-sort
            fixed-header
            hide-default-footer
            id="data-table"
            class="data-table"
            calculate-widths
          >
            <template v-slot:[`item.name`]="{ item }">
              <b @click="deleteOrderItem(item)" :id="'#' + item.pos">{{
                item.name
              }}</b>
            </template>
            <template v-slot:[`item.amount`]="{ item }"
              >{{ item.amount }}{{ item.unit }}</template
            >
            <template v-slot:[`item.price`]="{ item }">
              <span
                :class="{
                  styleFixedPrice: item.fixed,
                  styleSalePrice: item.sale
                }"
                >{{ toCurrency(item.price) }}</span
              >
            </template>
            <template
              v-if="this.selectedClient.includeVAT"
              v-slot:[`item.netSubtotal`]="{ item }"
              >{{ toCurrency(item.grossSubtotal) }}</template
            >
            <template v-else v-slot:[`item.netSubtotal`]="{ item }">{{
              toCurrency(item.netSubtotal)
            }}</template>
          </v-data-table>
        </v-card>
        <v-card tile class="sidebarTotals">
          <v-list dense>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Aantal:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                orderItems.length
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Collis:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                totalPackages
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Kratten:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                totalCrates
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content class="font-weight-bold"
                >Te betalen:</v-list-item-content
              >
              <v-list-item-content class="align-end font-weight-bold">{{
                toCurrency(grossTotal)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Excl. btw:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                toCurrency(netTotal)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item
              class="vlistitem"
              dense
              v-for="vat in VAT"
              :key="vat.name"
            >
              <v-list-item-content
                >{{ vat.name }} {{ vat.amount }}%:</v-list-item-content
              >
              <v-list-item-content class="align-end">{{
                toCurrency(vat.total)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="vlistitem" dense>
              <v-list-item-content>Totaal btw:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                toCurrency(totalVAT)
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-row no-gutters>
          <v-col>
            <v-btn
              @click="openClientModal"
              block
              color="primary"
              :disabled="!selectedClient._id"
              >Klant</v-btn>
              <v-btn block @click="openNewClientModal()">Nieuwe klant</v-btn>         
              <v-btn block color="red" @click="newOrder(true)">Reset</v-btn> 
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-row class="items" no-gutters>
      <v-col cols="12">
        <ul class="Items" v-if="!success && activeClient">
          <li v-for="(item, index) in categories" :key="item.itemNumber">
            <div class="item-container d-flex">
              <div class="item-name">
                <h3>
                  <a @click="openEditItemModal(item)">{{ item.name }}</a>
                </h3>
              </div>
              <div class="item-amount">
                <div class="d-flex justify-end">
                <button @click="deleteOrderItem(item)">
    {{ item.amount }}<span>{{item.unit}}</span>
  </button>
              </div>
              </div>
              <div class="item-buttons">
                <div class="btn-group">
    <button
      v-for="amount in setAmounts"
      :key="amount"
      @click="setAmount(index, amount)"
    >
      {{ amount }}
    </button>
              <button v-if="item.packaging=='Crate'" @click="item.crates=item.crates-1"><v-icon color="white">mdi-text-box-minus</v-icon></button>
              <button v-if="item.packaging=='Crate'" @click="item.crates=item.crates+1"><v-icon color="white">mdi-text-box-plus</v-icon></button>
              <button v-if="item.packaging=='Crate'" @click="item.crates=0">{{item.crates}}</button>
              </div>
            </div>
            </div>
          </li>
        </ul>
        <h1 v-else>Order opgeslagen</h1>
        <h1 v-if="!activeClient">Klant is geblokkeerd door administratie.</h1>
      </v-col>
    </v-row>
    <!-- 
    Notes
    -->
    <edit-item-modal
      v-if="editItemModal"
      :item="item"
      :editItemModal="editItemModal"
      :saveFixedPrices="true"
      :fixedPrices="fixedPrices"
      :client="client"
      @update-order-item="updateOrderItem"
      @cancel-edit-item-modal="cancelEditItemModal"
      @close-item="closeEditItemModal"
    ></edit-item-modal>
    <client-modal
      v-if="clientModal"
      :tabNumber="clientTabNumber"
      :client="selectedClient"
      :clientModal="clientModal"
      @insert-client="insertClient"
      @update-client="updateClient"
      @close-client-modal="clientModal = false"
    ></client-modal>
    <print-quote-modal
      v-if="printQuoteModal"
      :printQuoteModal="printQuoteModal"
      :quote="order"
      @close-print-quote-modal="closePrintQuoteModal"
    ></print-quote-modal>
    <print-order-modal
      v-if="printOrderModal"
      :printOrderModal="printOrderModal"
      :order="order"
      @close-print-order-modal="closePrintOrderModal"
    ></print-order-modal>
    <insert-invoice-modal
      v-if="insertInvoiceModal"
      printer="invoicePrinter"
      :insertInvoiceModal="insertInvoiceModal"
      :order="order"
      :invoice="invoice"
      :singleOrder="true"
      :openOrders="openOrders"
      :newOrder="true"
      @add-invoice-payments="openInvoicePaymentModal"
      @close-insert-invoice-modal="closeInsertInvoiceModal"
    ></insert-invoice-modal>
    <invoice-payment-modal
      v-if="invoicePaymentModal"
      :invoicePaymentModal="invoicePaymentModal"
      :invoice="invoice"
      @insert-payments="insertInvoicePayments"
      @close-payment-modal="closeInvoicePaymentModal"
    ></invoice-payment-modal>
  </div>
</template>
<script>
/* eslint-env es6 */
import searchClient from "../components/searchClient"
import editItemModal from "../components/editItemModal"
import invoicePaymentModal from "../components/invoicePaymentModal"
import clientModal from "../components/clientModal"
import printQuoteModal from "../components/printQuoteModal"
import printOrderModal from "../components/printOrderModal"
import insertInvoiceModal from "../components/insertInvoiceModal"
import datePicker from '@/components/datePicker.vue';
import {
  groupBy,
  errorHandler,
  getWeekNumber,
  fetchGET,
  fetchPOST,
  toCurrency,
  successHandler
} from "../js/functions.js"

import { mapState, mapGetters } from "vuex"
import Big from "big.js"
Array.prototype.groupBy = groupBy
export default {
  name: "newOrder",
  data() {
    return {
      datePicker: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0],
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0],
      dateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      client: { city: " " },
      activeClient: true,
      category: "",
      items: [{ sort: 0 }, { sort: 1 }],
      order: {},
      invoice: {},
      fixedPrices: [],
      salePrices: [],
      clientOrders: [],
      clientInvoices: [],
      recent: [],
      orderItemHeaders: [
        { text: "Artikel", value: "name" },
        { text: "Aantal", value: "amount" },
        { text: "Prijs", value: "price" },
        { text: "Subtotaal", value: "netSubtotal" }
      ],
      printQuoteModal: false,
      printOrderModal: false,
      insertInvoiceModal: false,
      editItemModal: false,
      searchClient: true,
      searchItems: "",
      newRoute: false,
      editRoute: false,
      orderItems: [],
      item: { amount: 0 },
      success: false,
      payments: [],
      invoicePaymentModal: false,
      clientModal: false,
      clientTabNumber: 0,
      searchString: ""
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    openClientModal() {
      this.clientTabNumber = 2
      this.clientModal = true
    },
    openNewClientModal() {
      this.clientTabNumber = 0
      this.searchString = ""
      this.$store.commit("selectClient", {country: "NL",active:true})
      this.clientModal = true
    },
    openInvoicePaymentModal(invoice) {
      this.success = true
      this.newOrder()
      this.invoice = invoice
      this.invoicePaymentModal = true
    },
    closeClientModal() {
      this.clientModal = false
    },
    closeInvoicePaymentModal() {
      this.invoicePaymentModal = false
      this.invoice = {}
    },
    async getClient(obj) {
      this.success = false
      this.searchItems = ""
      this.activeClient = obj.active
      this.$store.commit("selectClient", obj)
      this.order.route = this.selectedClient.defaultRoute
        ? this.selectedClient.defaultRoute
        : ""
      this.setClient(obj)
      this.searchClient = false
    },
    changeDate(date) {
        this.date = date
        this.dateFormatted = this.formatDate(date)
        let isoDate =new Date(date).toISOString().split("T")[0]
        let ws = this.$store.state.ws
        ws.send(
          JSON.stringify({
            event: "fetchOrders",
            data: { clientNumber: false, fromDate: isoDate, toDate: isoDate }
          })
        )
    },
    async setClient(client) {
      let clientData = await fetchGET("clientData", client.clientNumber)
      let fixedPrices = clientData[0].value
      this.clientOrders = clientData[1].value
      this.clientInvoices = clientData[2].value
      let recentOrders = clientData[3].value
      recentOrders.forEach(order =>
        order.items.forEach(item => this.recent.push(item.itemNumber))
      )
      if (this.recent.length) this.selectCategory("Recent")
      this.fixedPrices = fixedPrices
      let date = new Date(this.date)
      for (let item of this.items) {
        item.fixed = false
        item.sale = false
        item.price = parseFloat(item.inPrice)
        let fixedIndex = fixedPrices.findIndex(
          fix => fix.itemNumber == item.itemNumber
        )
        if (fixedIndex > -1) {
          item.fixed = true
          item.price = fixedPrices[fixedIndex].price
          if (
            new Date(fixedPrices[fixedIndex].fromDate) <= date &&
            new Date(fixedPrices[fixedIndex].toDate) >= date
          ) {
            item.sale = true
            item.price = fixedPrices[fixedIndex].salePrice
          }
        }
        let amount = Big(item.amount)
        let price = Big(item.price)
        let unitAmount = Big(item.unitAmount)
        let packages = Big()
        let totalWeight = Big()
        let netSubtotal = Big()
        let totalVAT = Big()
        let grossSubtotal = Big()
        item.VAT = this.selectedClient.export ? 0 : item.VAT
        let itemVAT = Big(item.VAT).div(100)
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2)
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount
                .times(price)
                .times(unitAmount)
                .round(2)
        if (client.includeVAT)
          netSubtotal = netSubtotal.div(
            Big(item.VAT)
              .plus(100)
              .div(100)
          )
        totalVAT = netSubtotal.times(itemVAT)
        grossSubtotal = netSubtotal.plus(totalVAT)
        item.packages = packages.toNumber()
        item.totalWeight = totalWeight.toNumber()
        item.netSubtotal = netSubtotal.toNumber()
        item.totalVAT = totalVAT.toNumber()
        item.grossSubtotal = grossSubtotal.toNumber()
        item.totalCost =
          item.unit == "kg"
            ? parseFloat(item.amount) * parseFloat(item.cost)
            : parseFloat(item.amount) *
              (parseFloat(item.unitAmount) * parseFloat(item.cost))
        item.totalCost = Math.round(item.totalCost * 100) / 100
        item.totalProfit =
          parseFloat(item.netSubtotal) - parseFloat(item.totalCost)
        let d = new Date(Date.now()).toISOString().split("T")
        d = d[0].split("-")
        let lotNumber = `${d[0].substr(-2)}/${d[1]}${d[2]}`
        item.lotNumber = item.lotNumber || lotNumber
      }
    },
    setSearchString(val) {
      this.searchString = val
    },
    async insertClient(client) {
      try {
        let response = await fetchPOST('insertClient',client)
        if(response.result.n==0) throw "insertClient"
        client = response.ops[0]
        this.$store.commit("selectClient",client )
        this.searchString = client.name
        successHandler("Klant opgeslagen")
        this.closeClientModal()
      } catch (e) {
        errorHandler(e,"Klant niet opgeslagen")
      }
    },
    async updateClient(client) {
      try {
        let response = await fetchPOST('updateClient',client)
        if(response.result.nModified==0) throw "updateClient"
        this.$store.commit("selectClient", client)
        this.searchString = client.name
        successHandler("Klant gewijzigd")
        this.closeClientModal()
      } catch (e) {
        errorHandler(e,"Klant niet gewijzigd")
      }
    },
    clearClient() {
      let confirmed
      if (this.orderItems.length && this.selectedClient._id && !this.success) {
        confirmed = confirm("Huidige order annuleren?")
      } else confirmed = true
      if (confirmed) {
        this.searchClient = true
        this.$store.commit("selectClient", {})
        this.newOrder()
        this.searchString = ""
      } else return false
    },
    async setAmount(index, plus) {
      // if (parseFloat(this.categories[index].price) < parseFloat(this.categories[index].inPrice)) this.categories[index].fixedPrice = true
      let reservedItem = JSON.parse(JSON.stringify(this.categories[index]))
      let amount = Big(this.categories[index].amount)
      let price = Big(this.categories[index].price)
      let unitAmount = Big(this.categories[index].unitAmount)
      let packages = Big()
      let crates = this.categories[index].crates
      let totalWeight = Big()
      let netSubtotal = Big()
      let totalVAT = Big()
      let grossSubtotal = Big()
      this.categories[index].VAT = this.selectedClient.export
        ? 0
        : this.categories[index].VAT
      let itemVAT = Big(this.categories[index].VAT).div(100)

      // let itemVATincluded = Big((100+this.categories[index].VAT).div(100))
      amount = amount.plus(plus)
      this.categories[index].amount = amount.toNumber()
      packages =
        this.categories[index].unit == "kg"
          ? amount.div(this.categories[index].packageAmount).round(undefined, 3)
          : amount
      crates = this.categories[index].packaging == "Crate" && (this.categories[index].unitAmount == 10 || this.categories[index].unitAmount == '10')
          ? amount.toNumber()
          : crates
      totalWeight =
        this.categories[index].unit == "kg"
          ? amount
          : amount.times(this.categories[index].unitAmount).round(2)
      netSubtotal =
        this.categories[index].unit == "kg"
          ? amount.times(price).round(2)
          : amount
              .times(price)
              .times(unitAmount)
              .round(2)
      if (this.selectedClient.includeVAT)
        netSubtotal = netSubtotal.div(
          Big(this.categories[index].VAT)
            .plus(100)
            .div(100)
        )
      totalVAT = netSubtotal.times(itemVAT)
      grossSubtotal = netSubtotal.plus(totalVAT)
      this.categories[index].packages = packages.toNumber()
      this.categories[index].crates = crates
      this.categories[index].totalWeight = totalWeight.toNumber()
      this.categories[index].netSubtotal = netSubtotal.toNumber()
      this.categories[index].totalVAT = totalVAT.toNumber()
      this.categories[index].grossSubtotal = grossSubtotal.toNumber()

      // console.log(this.categories[index].packages)
      this.categories[index].totalCost =
        this.categories[index].unit == "kg"
          ? parseFloat(this.categories[index].amount) *
            parseFloat(this.categories[index].cost)
          : parseFloat(this.categories[index].amount) *
            (parseFloat(this.categories[index].unitAmount) *
              parseFloat(this.categories[index].cost))
      this.categories[index].totalCost =
        Math.round(this.categories[index].totalCost * 100) / 100
      this.categories[index].totalProfit =
        this.categories[index].netSubtotal - this.categories[index].totalCost
      let itemIndex = this.orderItems.findIndex(
        item => item._id == this.categories[index]._id
      )
      if (itemIndex > -1) {
        this.categories[index] = this.orderItems[itemIndex]
      }
      if (itemIndex === -1) {
        this.categories[index].pos = this.orderItems.length || 0
        this.orderItems.push(this.categories[index])
      }
      this.categories
      let orderItems = this.orderItems
      let length = orderItems.length - 1
      // let update = reservedItem.amount != 0 ? true : false
      reservedItem.amount = amount
      // this.$store.state.ws.send(
      //   JSON.stringify({
      //     event: "reserveItem",
      //     data: { item: reservedItem, update: update, success: false }
      //   })
      // )
      setTimeout(function() {
        if (orderItems && orderItems.length) {
          let element = document.getElementById("#" + length)
          element.scrollIntoView(false)
        }
      }, 500)
    },
    selectCategory(cat) {
      if (this.success) this.newOrder()
      this.searchItems = ""
      this.category = cat
    },
    orderExists() {
      let orders = this.$store.state.orders
      for (let order of orders) {
        if (
          order.client._id === this.selectedClient._id &&
          order.status < 2 &&
          order.invoiceNumber == null
        ) {
          return order
        }
      }
      return false
    },
    insertOrderPayments(payments) {
      delete this.order._id
      this.order.payments = payments
      this.payments = payments
      this.paymentModal = false
    },
    insertInvoicePayments(payments) {
      this.invoice.payments = payments
      let totalPaid = payments.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.amount) * 100) / 100,
        0
      )
      this.invoice.credit = totalPaid
      this.invoice.debit =
        (this.invoice.grossTotal * 100 - totalPaid * 100) / 100
      this.invoice.status = this.invoice.debit == 0 ? 2 : 1
      this.updateInvoice(this.invoice)
      this.paymentModal = false
      this.success = true
      this.closeInvoicePaymentModal()
    },
    closePaymentModal() {
      delete this.order._id
      this.paymentModal = false
    },
    updateInvoice(invoice) {
      try {
        this.$store.commit("updateInvoice", invoice)//TODO: fix this
        let ws = this.$store.state.ws
        ws.send(JSON.stringify({ event: "updateInvoice", data: invoice }))
        successHandler("Factuur gewijzigd")
      } catch (e) {
        errorHandler(e,"Factuur niet gewijzigd")
      }
    },
    async newOrder(force) {
      if (this.success || force) {
        this.orderItems = []
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0]
        this.order = {}
        this.searchString = ""
        this.$store.commit("selectClient", {})
      }
      let response = await fetchGET("fetchItems", {
        options: { page: 0, itemsPerPage: 0, sortBy: [], sortDesc: [] }
      })
      this.items = response[0].results
      this.order = {
        assigned: "",
        payments: [],
        status: 0,
        credit: 0,
        debit: 0,
        route: ""
      }
      if(this.$store.state.settings.communication.motd)this.order.notes = this.$store.state.settings.communication.motd
      this.recent = []
      this.searchClient = true
      this.searchItems = ""
      this.newRoute = false
      this.editRoute = false
      this.success = false
    },
    async deleteOrderItem(val) {
      let reservedItem = JSON.parse(JSON.stringify(val))
      let index = this.orderItems.findIndex(item => item._id == val._id)
      if (index > -1) {
        this.orderItems.splice(index, 1)
        reservedItem.amount = -val.amount
        // this.$store.state.ws.send(
        //   JSON.stringify({
        //     event: "reserveItem",
        //     data: { item: reservedItem, update: true, success: false }
        //   })
        // )
        val.amount = 0
        val.netSubtotal = 0
        val.crates = 0
      }
    },
    updateOrderItem(val, oldVal) {
      let amount = Big(val.amount)
      let price = Big(val.price)
      let unitAmount = Big(val.unitAmount)
      let packages = Big()
      let crates = val.crates
      let totalWeight = Big()
      let netSubtotal = Big()
      let totalVAT = Big()
      let grossSubtotal = Big()
      val.VAT = this.selectedClient.export ? 0 : val.VAT
      let itemVAT = Big(val.VAT).div(100)
      packages =
        val.unit == "kg"
          ? amount.div(val.packageAmount).round(undefined, 3)
          : amount
      crates = val.packaging == "Crate" && (val.unitAmount == 10 || val.unitAmount == '10')
          ? amount.toNumber()
          : 0
      totalWeight =
        val.unit == "kg" ? amount : amount.times(val.unitAmount).round(2)
      netSubtotal =
        val.unit == "kg"
          ? amount.times(price)
          : amount.times(price).times(unitAmount)
      if (this.selectedClient.includeVAT)
        netSubtotal = netSubtotal.div(
          Big(val.VAT)
            .plus(100)
            .div(100)
        )     
      totalVAT = netSubtotal.times(itemVAT)
      grossSubtotal = netSubtotal.plus(totalVAT)
      val.amount = amount.toNumber()
      val.packages = packages.toNumber()
      val.crates = crates
      val.totalWeight = totalWeight.toNumber()
      val.netSubtotal = netSubtotal.toNumber()
      val.totalVAT = totalVAT.toNumber()
      val.grossSubtotal = grossSubtotal.toNumber()
      val.totalCost =
        val.unit == "kg"
          ? parseFloat(val.amount) * parseFloat(val.cost)
          : parseFloat(val.amount) *
            (parseFloat(val.unitAmount) * parseFloat(val.cost))
      val.totalCost = Math.round(val.totalCost * 100) / 100
      val.totalProfit = parseFloat(val.netSubtotal) - parseFloat(val.totalCost)
      let reservedItem = JSON.parse(JSON.stringify(val))
      reservedItem.amount = reservedItem.amount - oldVal.amount
      this.item.name = val.name
      this.item.amount = val.amount
      this.item.price = val.price
      this.item.fixed = val.fixed
      this.item.netSubtotal = val.netSubtotal
      this.item.grossSubtotal = val.grossSubtotal
      this.item.totalVAT = val.totalVAT
      this.item.totalProfit = val.totalProfit
      this.item.totalCost = val.totalCost
      this.item.packaging = val.packaging
      this.item.crates = val.crates
      this.item.VAT = val.VAT
      let itemIndex = this.orderItems.findIndex(item => item._id == val._id)
      if (itemIndex > -1) {
        if (parseInt(val.amount) == 0) {
          this.orderItems.splice(itemIndex, 1)
        }
      }
      if (itemIndex == -1) {
        this.item.pos = this.orderItems.length || 0
        this.orderItems.push(this.item)
      }

      // let update = oldVal.amount == 0 ? false : true
      // this.$store.state.ws.send(
      //   JSON.stringify({
      //     event: "reserveItem",
      //     data: { item: reservedItem, update: update, success: false }
      //   })
      // )

      this.closeEditItemModal()
    },
    openEditItemModal(item) {
      if (!e) var e = window.event
      e.cancelBubble = true
      if (e.stopPropagation) e.stopPropagation()
      this.item = item
      this.client = this.selectedClient
      this.editItemModal = true
    },
    closeEditItemModal() {
      this.client = {}
      this.editItemModal = false
    },
    cancelEditItemModal() {
      this.item = this.item.amount = 0
      this.editItemModal = false
    },
    createQuote() {
      try {
        let quote = JSON.parse(JSON.stringify(this.order))
        quote.clientNumber = this.selectedClient.clientNumber
        quote.client = {
          _id: this.selectedClient._id,
          clientNumber: this.selectedClient.clientNumber,
          active: this.selectedClient.active,
          name: this.selectedClient.name,
          address: this.selectedClient.address,
          postalcode: this.selectedClient.postalcode,
          city: this.selectedClient.city,
          country: this.selectedClient.country,
          phone: this.selectedClient.phone,
          mobile: this.selectedClient.mobile,
          email: this.selectedClient.email,
          contact: this.selectedClient.contact,
          includePrices: this.selectedClient.includePrices,
          includeVAT: this.selectedClient.includeVAT,
          export: this.selectedClient.export,
          exportEU: this.selectedClient.exportEU,
          defaultRoute: this.selectedClient.defaultRoute
        }
        if (this.selectedClient.shipping) {
          quote.client = {
            _id: this.selectedClient._id,
            clientNumber: this.selectedClient.clientNumber,
            active: this.selectedClient.active,
            name: this.selectedClient.name,
            phone: this.selectedClient.phone,
            mobile: this.selectedClient.mobile,
            email: this.selectedClient.email,
            contact: this.selectedClient.contact,
            includePrices: this.selectedClient.includePrices,
            includeVAT: this.selectedClient.includeVAT,
            export: this.selectedClient.export,
            exportEU: this.selectedClient.exportEU,
            shipping: true,
            shippingAddress: this.selectedClient.shippingAddress,
            shippingPostalcode: this.selectedClient.shippingPostalcode,
            shippingCity: this.selectedClient.shippingCity,
            shippingCountry: this.selectedClient.shippingCountry
          }
        }
        quote.status = 0
        let orderItems
          orderItems = JSON.parse(JSON.stringify(this.orderItems))
          quote.items = []
          for (let item of orderItems) {
            item.inAmount = item.amount
            item.inUnit = item.unit
            item.status = 0
            item.assigned = ""
            quote.items.push(item)
          }
        orderItems = orderItems.sort((a, b) => a.sort - b.sort)
        quote.totalProfit = orderItems.reduce(
          (acc, cur) => acc + cur.totalProfit,
          0
        )
        quote.totalCost = orderItems.reduce(
          (acc, cur) => acc + cur.totalCost,
          0
        )
        quote.name = this.selectedClient.name
        quote.route = this.order.route
        quote.sort = this.selectedClient.sort
          quote.totalPackages = this.totalPackages
          quote.totalWeight = this.totalWeight
          quote.netTotal = this.netTotal
          quote.grossTotal = this.grossTotal
        let orderVAT
        if (this.selectedClient.export) {
          orderVAT = [{ name: "Geen", amount: 0, total: quote.netTotal }]
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of orderVAT) {
            VAT.total = orderItems
              .filter(item => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if(cur.VAT==0)return (acc*100 + parseFloat(cur.netSubtotal)*100)/100
                else return (acc*100 + parseFloat(cur.totalVAT)*100)/100
              }, 0)
          }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        quote.VAT = VAT
        quote.totalVAT = orderItems
          .filter(item => item.VAT > 0)
          .reduce((acc, cur) => (acc * 100 + cur.totalVAT * 100) / 100, 0)
        quote.items = orderItems
        quote.printed = false
        quote.emailed = false
        quote.emailStatus = ""
        if (this.orderNotes) quote.notes = this.orderNotes
        quote.date = this.date
        quote.deliveryDate = this.date
        let date = new Date(this.date)
        quote.day = date.getDate()
        quote.month = date.getMonth() + 1
        quote.year = date.getFullYear()
        quote.week = getWeekNumber(date)
        quote.quarter = Math.ceil(quote.month / 3)
        quote.expand = false
        quote.selectable = true
        return quote
      } catch (e) {
        console.log(e)
      }
    },
    createOrder(direct, invoice, update) {
      try {
        let order = JSON.parse(JSON.stringify(this.order))
        order.clientNumber = this.selectedClient.clientNumber
        order.client = {
          _id: this.selectedClient._id,
          clientNumber: this.selectedClient.clientNumber,
          active: this.selectedClient.active,
          name: this.selectedClient.name,
          address: this.selectedClient.address,
          postalcode: this.selectedClient.postalcode,
          city: this.selectedClient.city,
          country: this.selectedClient.country,
          phone: this.selectedClient.phone,
          mobile: this.selectedClient.mobile,
          email: this.selectedClient.email,
          contact: this.selectedClient.contact,
          includePrices: this.selectedClient.includePrices,
          includeVAT: this.selectedClient.includeVAT,
          export: this.selectedClient.export,
          exportEU: this.selectedClient.exportEU,
          defaultRoute: this.selectedClient.defaultRoute,
          invoiceFrequency: this.selectedClient.invoiceFrequency
        }
        if (this.selectedClient.shipping) {
          order.client = {
            _id: this.selectedClient._id,
            clientNumber: this.selectedClient.clientNumber,
            active: this.selectedClient.active,
            name: this.selectedClient.name,
            phone: this.selectedClient.phone,
            mobile: this.selectedClient.mobile,
            email: this.selectedClient.email,
            contact: this.selectedClient.contact,
            includePrices: this.selectedClient.includePrices,
            includeVAT: this.selectedClient.includeVAT,
            export: this.selectedClient.export,
            exportEU: this.selectedClient.exportEU,
            shipping: true,
            shippingAddress: this.selectedClient.shippingAddress,
            shippingPostalcode: this.selectedClient.shippingPostalcode,
            shippingCity: this.selectedClient.shippingCity,
            shippingCountry: this.selectedClient.shippingCountry,
            invoiceFrequency: this.selectedClient.invoiceFrequency
          }
        }
        if (invoice) order.invoiceNumber = 0
        order.status = 0
        if (direct) order.status = 2
        let itemExists = false
        let orderItems
        if (!update) {
          orderItems = JSON.parse(JSON.stringify(this.orderItems))
          order.items = []
          for (let item of orderItems) {
            item.inAmount = item.amount
            item.inUnit = item.unit
            item.status = 0
            item.assigned = ""
            if (order.assigned || direct) item.status = 1
            order.items.push(item)
          }
        } else {
          orderItems = JSON.parse(JSON.stringify(order.items))
          for (let item of this.orderItems) {
            for (let orderItem of orderItems) {
              if (
                orderItem.itemNumber == item.itemNumber &&
                orderItem.unit == item.unit
              ) {
                let amount = Big(orderItem.amount)
                let packages = Big(orderItem.packages)
                let crates = orderItem.crates
                let totalWeight = Big(orderItem.totalWeight)
                let netSubtotal = Big(orderItem.netSubtotal)
                let totalVAT = Big(orderItem.totalVAT)
                let grossSubtotal = Big(orderItem.grossSubtotal)
                orderItem.amount = amount.plus(item.amount).toNumber()
                orderItem.packages = packages.plus(item.packages).toNumber()
                orderItem.crates = crates + item.crates
                orderItem.totalWeight = totalWeight.plus(item.totalWeight).toNumber()
                orderItem.netSubtotal = netSubtotal.plus(item.netSubtotal).toNumber()
                orderItem.grossSubtotal = grossSubtotal.plus(item.grossSubtotal).toNumber()
                orderItem.totalVAT = totalVAT.plus(item.totalVAT).toNumber()
                orderItem.totalCost =
                  parseFloat(orderItem.totalCost) + parseFloat(item.totalCost)
                orderItem.totalProfit =
                  parseFloat(orderItem.totalProfit) +
                  parseFloat(item.totalProfit)
                orderItem.status = 0
                orderItem.assigned = ""
                if (order.assigned || direct) orderItem.status = 1
                itemExists = true
              }
            }
            if (!itemExists) {
              item.inAmount = item.amount
              item.inUnit = item.unit
              item.status = 0
              item.assigned = ""
              if (order.assigned) item.status = 1
              if (direct) item.status = 2
              orderItems.push(item)
            }
            itemExists = false
          }
        }
        orderItems = orderItems.sort((a, b) => a.sort - b.sort)
        order.totalProfit = orderItems.reduce(
          (acc, cur) => acc + cur.totalProfit,
          0
        )
        order.totalCost = orderItems.reduce(
          (acc, cur) => acc + cur.totalCost,
          0
        )
        order.name = this.selectedClient.name
        order.route = this.order.route
        order.sort = this.selectedClient.sort
        if (!update) {
          order.totalPackages = this.totalPackages
          order.totalCrates = this.totalCrates
          order.totalWeight = this.totalWeight
          order.netTotal = this.netTotal
          order.grossTotal = this.grossTotal
        } else {
          order.totalPackages =
            (order.totalPackages * 100 + this.totalPackages * 100) / 100
          order.totalCrates =
            (order.totalCrates * 100 + this.totalCrates * 100) / 100
          order.totalWeight =
            (order.totalWeight * 100 + this.totalWeight * 100) / 100
          order.netTotal = (order.netTotal * 100 + this.netTotal * 100) / 100
          order.grossTotal =
            (order.grossTotal * 100 + this.grossTotal * 100) / 100
        }
        let orderVAT
        if (this.selectedClient.export) {
          orderVAT = [{ name: "Geen", amount: 0, total: order.netTotal }]
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of orderVAT) {
            VAT.total = orderItems
              .filter(item => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if(cur.VAT==0)return (acc*100 + parseFloat(cur.netSubtotal)*100)/100
                else return (acc*100 + parseFloat(cur.totalVAT)*100)/100
              }, 0)
          }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        order.VAT = VAT
        order.totalVAT = orderItems
          .filter(item => item.VAT > 0)
          .reduce((acc, cur) => (acc * 100 + cur.totalVAT * 100) / 100, 0)
        let totalPaid = 0
        if (order.payments.length) {
          totalPaid = order.payments.reduce(
            (acc, cur) => (acc * 100 + parseFloat(cur.amount) * 100) / 100,
            0
          )
        }
        if (order.assigned) order.status = 1
        order.items = orderItems
        order.credit = totalPaid
        order.debit = update
          ? (order.grossTotal * 100 - totalPaid * 100) / 100
          : (this.grossTotal * 100 - totalPaid * 100) / 100
        order.printed = false
        order.emailed = false
        order.emailStatus = ""
        if (this.orderNotes) order.notes = this.orderNotes
        order.packages = 0
        order.date = this.date
        let date = new Date(this.date)
        order.day = date.getDate()
        order.month = date.getMonth() + 1
        order.year = date.getFullYear()
        order.week = getWeekNumber(date)
        order.quarter = Math.ceil(order.month / 3)
        order.expand = false
        order.selectable = true
        return order
      } catch (e) {
        console.log(e)
      }
    },
  async insertQuote() {
      try {
        if (
          this.selectedClient._id
        ) {
          delete this.order._id
          let quote  = this.createQuote()
          this.success = true
          let res = await fetchPOST("insertQuote", quote)
          quote = res.ops[0]
          this.$store.commit("insertQuote", quote)
          successHandler("Offerte opgeslagen")
          return quote
        } else {
          let message = ""
          if (!this.selectedClient._id) message += "-Selecteer een klant"        
          alert(message)
        }
      } catch (e) {
        errorHandler(e, "Order niet opgeslagen")
      }
    },
    async insertOrder() {
      try {
        if (
          this.selectedClient._id &&
          this.orderItems.length &&
          this.order.route !== ""
        ) {
          let order = this.orderExists()
          let event
          let addToExistingOrder = false
          let d = new Date(Date.now())
          let hours = d.getHours()
          if (hours >= 18 && hours < 24 && this.date === this.today) {
            let confirmTomorrow = confirm("Order verplaatsen naar morgen?")
            if (confirmTomorrow) {
              let day = d.getDate() + 1
              d.setDate(day)
              this.date = d.toISOString().split("T")[0]
            }
          }
          if (order && !this.payments.length) {
            addToExistingOrder = confirm("Toevoegen aan bestaande order?")
          }
          if (addToExistingOrder) {
            this.order = order
            order = this.createOrder(false, false, true)
            event = "updateOrder"
            this.$store.commit("updateOrder", order)
          } else {
            delete this.order._id
            order = this.createOrder(false, false, false)
            event = "insertOrder"
          }
          this.success = true
          let res = await fetchPOST(event, order)
          if (!addToExistingOrder) order = res.ops[0]
          this.$store.commit("insertOrder", order)
          successHandler("Order opgeslagen")
          return order
        } else {
          let message = ""
          if (!this.selectedClient._id) message += "-Selecteer een klant"
          if (!this.orderItems.length) message += "\n-Selecteer een item"
          if (this.order.route == "") message += "\n-Selecteer een route"
          alert(message)
        }
      } catch (e) {
        errorHandler(e, "Order niet opgeslagen")
      }
    },
    async openPrintQuoteModal() {
      if (
        this.selectedClient._id &&
        this.orderItems.length &&
        this.order.route !== ""
      ) {
        this.order = await this.insertQuote()
        this.success = true
        this.printQuoteModal = true
      } else {
        let message = ""
        if (!this.selectedClient._id) message += "-Selecteer een klant"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        if (this.order.route == "") message += "\n-Selecteer een route"
        alert(message)
      }
    },
    closePrintQuoteModal() {
      this.newOrder()
      this.quote = {}
      this.printQuoteModal = false
    },
    async openPrintOrderModal() {
      if (
        this.selectedClient._id &&
        this.orderItems.length &&
        this.order.route !== ""
      ) {
        this.order = await this.insertOrder()
        this.success = true
        this.printOrderModal = true
      } else {
        let message = ""
        if (!this.selectedClient._id) message += "-Selecteer een klant"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        if (this.order.route == "") message += "\n-Selecteer een route"
        alert(message)
      }
    },
    openPrintInvoiceModal() {
      if (
        this.selectedClient._id &&
        this.orderItems.length &&
        this.order.route !== ""
      ) {
        this.order = this.createOrder(true)
        this.invoice.orders = []
        this.invoice.payments = []
        this.invoice.client = this.selectedClient
        this.insertInvoiceModal = true
      } else {
        let message = ""
        if (!this.selectedClient._id) message += "-Selecteer een klant"
        if (!this.orderItems.length) message += "\n-Selecteer een item"
        if (this.order.route == "") message += "\n-Selecteer een route"
        alert(message)
      }
    },
    closePrintOrderModal() {
      this.newOrder()
      this.printOrderModal = false
    },
    closePrintInvoiceModal() {
      this.insertInvoiceModal = false
    },
    closeInsertInvoiceModal() {
      this.insertInvoiceModal = false
    },
    createInvoice() {
      console.log("create invoice")
      let orders = this.invoice.orders
      let d = new Date(this.date)
      let day = d.getDate()
      let week = getWeekNumber(d)
      let month = d.getMonth() + 1
      let year = d.getFullYear()
      let quarter = Math.ceil(month / 3)
      let date = new Date(this.date)
      let payments = this.invoice.orders
        .map(order => order.payments)
        .filter(payment => payment.length > 0)
        .flat()
      let totalPaid = payments.length
        ? payments.reduce((acc, cur) => (acc * 100 + cur.amount * 100) / 100, 0)
        : 0
      let credit = totalPaid
      let netTotal = orders.reduce((acc, cur) => {
        return (acc * 100 + cur.netTotal * 100) / 100
      }, 0)
      let totalVAT = orders.reduce((acc, cur) => {
        return (acc * 100 + cur.totalVAT * 100) / 100
      }, 0)
      let grossTotal = orders.reduce((acc, cur) => {
        return (acc * 100 + cur.grossTotal * 100) / 100
      }, 0)
      let debit = (grossTotal * 100 - credit * 100) / 100
      let status = debit == 0 ? 2 : 1
      let payTerms = this.selectedClient.payTerms >= 0 ? this.selectedClient.payTerms : this.$store.state.settings.payTerms
      let invoiceVAT
      if (this.selectedClient.export) {
        invoiceVAT = [{ name: "Geen", amount: 0, total: netTotal }]
      } else {
        invoiceVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
        for (let VAT of invoiceVAT) {
          VAT.total = orders.reduce((acc, cur) => {
            return (
              (acc * 100 +
                cur.VAT.filter(vat => vat.amount == VAT.amount).reduce(
                  (acc2, cur2) => (acc2* 100 + cur2.total * 100) / 100,
                  0
                ) *
                  100) /
              100
            )
          }, 0)
        }
      }
      invoiceVAT = invoiceVAT.filter(VAT => parseFloat(VAT.total) !== 0)
      d.setDate(date.getDate() + payTerms)
      let dueDate = new Date(d.toISOString().split("T")[0])
      let invoice = {
        client: this.selectedClient,
        clientNumber: this.selectedClient.clientNumber,
        name: this.selectedClient.name,
        orders: this.invoice.orders,
        date: this.date,
        dueDate: dueDate,
        day: day,
        week: week,
        month: month,
        year: year,
        quarter: quarter,
        netTotal: netTotal,
        totalVAT: totalVAT,
        grossTotal: grossTotal,
        credit: credit,
        debit: debit,
        payments: payments,
        totalPaid: totalPaid,
        VAT: invoiceVAT,
        status: status,
        printed: true,
        expand: false,
        type: "sales",
        notes: "",
        totalProfit: 0,
        totalCost: 0,
        discount: 0,
        totalDiscount: 0
      }
      return invoice
    },
    toCurrency(n) {
      return toCurrency(n)
    }
  },
  computed: {
    selectedClient() {
      return this.$store.state.selectedClient
    },
    categories() {
      console.log("categories")
      let items = this.items
      items = items.sort((a, b) => a.sort - b.sort)
      let recent = this.recent
      let categories = items.groupBy("category", recent)
      if (this.searchItems) {
        return items.filter(
          item =>
            item.name.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1
        )
      }
      return categories[this.category]
    },
    totalPackages() {
      let totalPackages = this.orderItems.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.packages) * 100) / 100,
        0
      )
      return totalPackages
    },
    totalCrates() {
      let totalCrates = this.orderItems.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.crates) * 100) / 100,
        0
      )
      return totalCrates
    },
    totalWeight() {
      let totalWeight = this.orderItems.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalWeight) * 100) / 100,
        0
      )
      return totalWeight
    },
    netTotal() {
      let netTotal = this.orderItems.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
        0
      )
      return netTotal
    },
    grossTotal() {
      let grossTotal =
        (parseFloat(this.netTotal) * 100 + parseFloat(this.totalVAT) * 100) /
        100
      return grossTotal
    },
    credit() {
      let credit = this.order.payments.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.amount) * 100) / 100,
        0
      )
      return credit
    },
    debit() {
      return (this.grossTotal * 100 - this.credit * 100) / 100
    },
    totalVAT() {
      let totalVAT = this.VAT.filter(vat => vat.amount > 0).reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.total) * 100) / 100,
        0
      )
      return totalVAT
    },
    VAT() {
      let orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
      if (this.selectedClient.export) {
        return [{ name: "Geen", amount: 0, total: this.netTotal }]
      }
      for (let VAT of orderVAT) {
        VAT.total = this.orderItems
          .filter(item => VAT.amount === item.VAT)
          .reduce((acc, cur) => {
                if(cur.VAT==0)return (acc*100 + parseFloat(cur.netSubtotal)*100)/100
                else return (acc*100 + parseFloat(cur.totalVAT)*100)/100
              }, 0)
      }
      return orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
    },
    routes() {
      let routes = [...this.$store.state.settings.routes]
      return routes.sort()
    },
    docks() {
      return this.$store.state.settings.docks
    },
    setAmounts() {
      return this.$store.state.settings.setAmounts
    },
    orderDebit() {
      return this.clientOrders.reduce((acc, cur) => acc + cur.debit, 0)
    },
    invoiceDebit() {
      return this.clientInvoices.reduce((acc, cur) => acc + cur.debit, 0)
    },
    ...mapState(["active", "payMethods", "employees", "openOrders"]),
    ...mapGetters(["allRoutes"])
  },
  watch: {
    async date(val) {
      this.dateFormatted = this.formatDate(val)
      if (!this.success) {
        this.$store.dispatch("fetchGET", {
          type: "fetchOrders",
          data: { clientNumber: false, fromDate: val, toDate: val }
        })
      }
    }
  },
  async mounted() {
    await this.newOrder()
    if (this.selectedClient.clientNumber) {
      this.searchString = this.selectedClient.name
      this.getClient(this.selectedClient)
    }
  },
  components: {
    searchClient,
    editItemModal,
    invoicePaymentModal,
    clientModal,
    printQuoteModal,
    printOrderModal,
    insertInvoiceModal,
    datePicker
  }
}
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header sidebar" "items sidebar";
  position: relative;
  /* background: #69a9e4; */
}
.header {
  grid-area: header;
  position: sticky;
  top: 0;
  background: var(--v-container_background-base);
  z-index: 1;
}
.header .header-card {
  padding: 10px 5px 0 5px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-evenly;
  border: 1px solid #ccc;
  /* background: #69a9e4; */
  border-radius: 5px;
}
.orders-filtered-by-route {
  list-style: circle;
  height: 150px;
  overflow: auto;
}
.vlistitem {
  height: 20px;
  min-height: 20px;
}
.input-date-container {
  margin-left: 35px;
}
.categories-container {
  display: flex;
  flex-direction: row;
}
.select {
  background: white;
  width: 100%;
  height: 30px;
  font-size: 20px;
  border: 1px solid #ccc;
}
input {
  height: 30px;
  font-size: 1.2em;
}
#date {
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
}
.sidebar {
  font-size: 14px;
  grid-area: sidebar;
  width: 300px;
  height: 100%;
  border-left: 1px solid lightgray;
  position: fixed;
  top: 55px;
  right: 1px;
  z-index: 2;
  display: inline-block;
  overflow-y: auto;
  /* background: #69a9e4; */
}
.sidebar-flex-container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}
#route-radio span {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  text-align: center;
  border-radius: 35px;
  border: 3px solid black;
  display: inline-block;
}
select.v-select-select {
  min-height: 100px !important;
  max-height: 100px !important;
  display: flex !important;
  align-items: center !important;
}
.selected-route {
  background: black;
  color: white;
}
#data-table,
#data-table table tr {
  border-radius: 0;
  /* background: #69a9e4; */
}
.btn-group button {
  background-color: #3066BE; /* Green background */
  border: 1px solid #3066BE; /* Green border */
  color: white; /* White text */
  padding: 10px 15px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}
.btn-group button:focus {
  outline: none;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: darkblue;
}
.order-items {
  text-align: left;
  /* height: 390px; */
  text-align: left;
  overflow-y: scroll;
}
.styleFixedPrice {
  background: red;
  color: white;
}
.styleSalePrice {
  background: green;
  color: white;
}
.Items {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.Items li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media only screen and (max-width: 600px) {
  .sidebar {
    position: relative;
  }
  .Items {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.big-input {
  font-size: 20px;
}
.items {
  grid-area: items;
}
.sidebarTotals {
  text-align: right;
}
ul {
  list-style: none;
}
.center {
  text-align: center;
}
#success {
  font-size: 2em;
  display: inline-block;
}
.categories {
  height: 18px;
  /* vertical-align: middle; */
}
#filter div span {
  display: table;
  position: absolute;
}
#filter div {
  display: inline-block;
  width: 100px;
}
#filter table {
  margin: auto;
}
.options {
  position: relative;
  width: 60px;
  height: 30px;
  font-size: 0.9em;
}
textarea {
  border: 1px solid lightgray;
  width: 50%;
}
.fieldset {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  min-width: 200px;
  max-width: 300px;
  height: 70px;
  padding: 5px;
  font-size: 1.2rem;
  margin-top: -13px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 1.2rem;
  font-weight: 400;
}
.item-container {
  width: 100%;
}
.item-name {
width: 40%;
border-bottom: 1px solid black;
}
.item-amount{
width: 20%;
border-bottom: 1px solid black;
}
.item-amount div button {
  width: 70%;
  text-align:left;
  font-size: 1.5em;
}
.item-amount div span{
font-size: 10px;
}
.item-buttons{
width: 40%;
}
.small-text-field {
  width: 33%;
  min-height: 70px;
}
.search-date {
  min-width: 150px;
  max-width: 150px;
  margin-left: 5px;
}
.search-client {
  min-width: 350px;
  max-width: 350px;
  margin-left: 5px;
}
@media screen and (max-width: 1200px) {
.search-date {
    min-width: 100%;
    margin-bottom: 10px;
  }
.search-client {
    min-width: 100%;
    margin-bottom: 10px;
  }
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
</style>